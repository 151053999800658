/*
莅视医疗平台----PC
----服务器接口连接地址
1、本地17 2、开发wingstiger 3、海泰 4、测试
*/
let env_config = 3;

/*
----配置资源项 
1、wingstiger 2、eliscn 3、海泰新光  4、国药新光
*/
let static_config = 4;

export {
    env_config,
    static_config
}
