let global_static = function () {
    this.getRoleCodeList = () => {
        let list = [
            {
                roleCode: 0,
                roleCodeName: "超级管理员",
            },
            {
                roleCode: 1,
                roleCodeName: "手术医生",
            },
            {
                roleCode: 2,
                roleCodeName: "专家医生",
            },
            {
                roleCode: 3,
                roleCodeName: "业务员",
            },
            {
                roleCode: 4,
                roleCodeName: "未实名认证",
            },
            {
                roleCode: 5,
                roleCodeName: "其他认证",
            },
            {
                roleCode: 6,
                roleCodeName: "认证医生",
            },
            {
                roleCode: 7,
                roleCodeName: "手术医生未认证",
            },
            {
                roleCode: 9,
                roleCodeName: "管理员",
            },
            {
                roleCode: 10,
                roleCodeName: "系统管理员",
            },
        ];
        return list;
    }
}
export default global_static;